import { ReactElement, useEffect, useState } from 'react';
import { useInjectable } from '@/src/services';
import { useForm } from 'react-hook-form';
import {
  ConversationsApiServiceContainerType,
  IConversationsApiService,
} from '@/src/services/conversations-api';
import { PromptEditType } from 'charlie-workflows/contracts/prompts';
import {
  IToasterService,
  ToasterServiceContainerType,
} from '@/src/services/toaster';

import styles from './prompt-editor-modal.module.scss';
import { Modal } from '@/src/shared/ui-kit/components/modal';

const tabs: { name: string; controlName: PromptEditType }[] = [
  { name: 'Conversation', controlName: 'conversation' },
  { name: 'Recommendations', controlName: 'recommendations' },
  { name: 'Capsules', controlName: 'capsules' },
  { name: 'Questions', controlName: 'questions' },
  { name: 'Profile Sub Agent', controlName: 'profileSubAgent' },
];

interface IPromptModalProps {
  sessionId?: string;
  onClose?: () => void;
}

export const PromptEditorModal: React.FC<IPromptModalProps> = ({
  sessionId,
  onClose,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [controls, setControls] = useState<ReactElement[]>([]);
  const [prompts, setPrompts] = useState<Record<
    PromptEditType,
    string | undefined
  > | null>({
    questions: '',
    capsules: '',
    conversation: '',
    recommendations: '',
    profileSubAgent: '',
  });

  const { getUserPrompts, resetToDefaultPrompts, saveConversationPrompt } =
    useInjectable<IConversationsApiService>(
      ConversationsApiServiceContainerType,
    );

  const { success } = useInjectable<IToasterService>(
    ToasterServiceContainerType,
  );

  const { register, setValue, getValues } =
    useForm<Record<PromptEditType, string | undefined>>();

  useEffect(() => {
    getUserPrompts().subscribe(setPrompts);
  }, [getUserPrompts]);

  useEffect(() => {
    setControls(
      tabs.map((tab) => (
        <textarea
          key={tab.name}
          className={styles.textarea}
          {...register(tab.controlName)}
        />
      )),
    );
  }, [register]);

  useEffect(() => {
    if (prompts) {
      Object.keys(prompts).forEach((key) => {
        setValue(key as PromptEditType, prompts[key as PromptEditType]);
      });
    }
  }, [prompts, setValue]);

  return (
    <Modal.Body
      className={styles.container}
      onClick={(event) => event.stopPropagation()}
    >
      <h2 className={styles.header}>Prompt Editor</h2>
      <div className={styles.tabs}>
        {tabs.map(({ name }, index) => (
          <div
            key={name}
            className={`${styles.tab} ${
              tabIndex === index ? styles.active : ''
            }`}
            onClick={() => setTabIndex(index)}
          >
            {name}
          </div>
        ))}
      </div>
      <div className={styles.body}>
        {tabIndex >= 0 ? controls[tabIndex] : null}
      </div>

      <div className={styles.actions}>
        <button
          onClick={() => {
            resetToDefaultPrompts().subscribe(setPrompts);
            onClose?.();
          }}
        >
          Discard changes
        </button>
        <button
          className="primary"
          onClick={() => {
            const values = getValues();
            if (values) {
              const { controlName } = tabs[tabIndex];

              const data = values[controlName];
              const metadata = {
                promptType: controlName,
                prompt: data || '',
              };
              saveConversationPrompt(metadata).subscribe(() => {
                setPrompts(values);
                success(`Prompt for ${tabs[tabIndex].name} is updated`);
                onClose?.();
              });
            }
          }}
        >
          Update prompt
        </button>
      </div>
    </Modal.Body>
  );
};
