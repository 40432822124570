import { useUser } from '@auth0/nextjs-auth0/client';
import { AnimatePresence } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { MouseEvent, useState } from 'react';
import { HiMenu } from 'react-icons/hi';
import dynamic from 'next/dynamic';

import LogoIcon from '@/public/assets/logo-black.svg';
import ProfileIcon from '@/public/assets/profile.svg';

import { SignUpModal } from '@/src/components/modals/sign-up-modal/sign-up-modal.component';
import { PromptEditorModal } from '@/src/components/modals/prompt-editor-modal/prompt-editor-modal.component';
import { useModal } from '@/src/services/modal';

import styles from './top-navigation.module.scss';

const Aside = dynamic(() =>
  import('@/src/shared/ui-kit/components/aside').then(({ Aside }) => Aside),
);

export interface TopNavigationProps {
  homePath?: string;
  withBackground?: boolean;
  isBeta?: boolean;
  className?: string;
}

export const TopNavigation: React.FC<TopNavigationProps> = ({
  homePath = '/',
  withBackground = false,
  isBeta = false,
  className,
}) => {
  const router = useRouter();
  const userInfo = useUser();

  const [isAsideVisible, setIsAsideVisible] = useState(false); // Perfomance optimization
  const sessionId = Array.isArray(router.query.id)
    ? router.query.id[0]
    : router.query.id;
  const preventLeavingModal = router.pathname.includes('/shared/');
  const onDrawerClick = () => {
    setIsAsideVisible((value) => !value);
  };

  const isUserAuthenticated = Boolean(userInfo.user);
  const isConfabulationUser = Boolean(
    String(userInfo.user?.email).endsWith('@confabulation.ai'),
  );
  const onSignUp = () => {
    open(`/api/auth/login?returnTo=${router.asPath}`, '_self');
  };

  const { open: openSignUp, close: closeSignUp } = useModal((props) => (
    <SignUpModal
      onSignUp={onSignUp}
      onClose={() => {
        closeSignUp();
        open(homePath, '_self');
      }}
    />
  ));

  const { open: openPromptEditor, close: closePromptEditor } = useModal(
    (props) => (
      <PromptEditorModal sessionId={sessionId} onClose={closePromptEditor} />
    ),
  );

  const onLeaveToHome = (e: MouseEvent) => {
    e.preventDefault();

    if (!isUserAuthenticated && sessionId && !preventLeavingModal) {
      openSignUp({
        bodyClassName: 'signUpModalBody',
        contentClassName: 'signUpModalContent',
      });
    } else {
      open(homePath, '_self');
    }
  };

  return (
    <nav className={`${className || ''} ${styles.container}`}>
      {isAsideVisible ? (
        <Aside
          isDrawerOpened={isAsideVisible}
          setIsDrawerOpened={setIsAsideVisible}
        />
      ) : null}

      <AnimatePresence>
        {withBackground ? (
          <div className="topNavOverlay">
            <div className="smoothBackground"></div>
          </div>
        ) : null}
      </AnimatePresence>

      <div className={styles.logoContainer}>
        <Link href={homePath} onClick={onLeaveToHome}>
          <Image
            className={styles.logo}
            src={LogoIcon}
            quality={100}
            alt="Ollie"
          />
        </Link>
        {isBeta ? <span className={styles.betaCaption}>Beta</span> : null}
      </div>

      <div className={styles.contentContainer}>
        <Link
          className={styles.saved}
          href={`/wishlists?returnTo=${router.asPath}`}
        >
          <button className={styles.menuButton}>Saved</button>
        </Link>
        {router.route !== '/' ? (
          <Link href={homePath} onClick={onLeaveToHome}>
            <button className={`primary ${styles.newSearch}`}>
              New search
            </button>
          </Link>
        ) : null}

        {isConfabulationUser ? (
          <button
            className={`primary ${styles.desktopPromptEditor}`}
            onClick={() => {
              openPromptEditor({
                className: styles.modalContainer,
                bodyClassName: styles.modalBodyContainer,
              });
            }}
          >
            Prompt editor
          </button>
        ) : null}

        {!isUserAuthenticated ? (
          <Link href={`/api/auth/login?returnTo=${router.asPath}`}>
            <button className={`primary ${styles.loginButton}`}>Sign in</button>
          </Link>
        ) : (
          <>
            <button
              className={`primary ${styles.userPreview} ${
                userInfo.user?.picture ? styles.userPreviewSocial : ''
              }`}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Link href={`/profile?returnTo=${router.asPath}`}>
                <Image
                  src={userInfo.user?.picture || ProfileIcon}
                  fill
                  quality={100}
                  alt="Profile"
                />
              </Link>
            </button>
          </>
        )}
        <HiMenu
          className={`${styles.burgerMenuIcon} ${
            isAsideVisible ? styles.opened : ''
          }`}
          onClick={onDrawerClick}
        />
      </div>
    </nav>
  );
};
